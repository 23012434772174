/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Button,
  Container,
  Step,
  StepButton,
  StepContent,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { PublicKey } from '@solana/web3.js';
import Moment from 'react-moment';
import StepDescription from '../components/StepDescription';
import { useStyles } from './useStyles';
import { useAppDispatch, useAppSelector } from '../hooks';
import { getWalletAddress } from '../store/selectors';
import { setWalletAddress } from '../store/stakeSlice';
import SolanaWalletKey from '../components/SolanaWalletKey';
import { useSolanaWallet } from '../contexts/SolanaWalletContext';
import useHistory, {
  HistoryErrorCode,
  HistoryStatusCode,
} from '../hooks/useHistory';
import { isAddress as isAddressForSolana } from '../utils/solanaHelper';
import { CHAIN_ID_SOLANA } from '../lib/consts';
import ShowTxButton from '../components/ShowTxButton';
import useClaim, { ClaimStatusCode, getClaimParams } from '../hooks/useClaim';
import { STATUS_CLAIMED, STATUS_STAKED } from '../utils/hodlHelper';

export const StatusView = () => {
  const classes = useStyles();
  const walletAddress = useAppSelector(getWalletAddress);
  const dispatch = useAppDispatch();
  const { publicKey: solanaAddress } = useSolanaWallet();
  const [successMessage, setSuccessMessage] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [claimSuccessMessage, setClaimSuccessMessage] = React.useState('');
  const [claimErrorMessage, setClaimErrorMessage] = React.useState('');
  const {
    history,
    updateHistoryForClaim,
    historyResult,
    isProcessing,
    statusCode,
    errorCode,
    lastError,
  } = useHistory();

  const {
    claim,
    isProcessing: isClaimProcessing,
    statusCode: claimStatusCode,
    lastError: claimLastError,
    sourceTxId: stakeTxId,
    targetTxId: claimResultTxId,
  } = useClaim(updateHistoryForClaim);

  useEffect(() => {
    dispatch(setWalletAddress(solanaAddress ? solanaAddress.toString() : ''));
  }, [dispatch, solanaAddress]);

  const handleWalletAddressChange = useCallback(
    (event) => {
      dispatch(setWalletAddress(event.target.value));
    },
    [dispatch],
  );

  const onHistoryButtonClick = async () => {
    if (walletAddress) {
      history();
    }
  };

  const handleClaimButtonClick = (txId: string, merkleInfo: unknown) => {
    claim(txId, getClaimParams(merkleInfo));
  };

  const statusMessage = useMemo(() => {
    if (isProcessing || statusCode !== HistoryStatusCode.FAILED) {
      switch (statusCode) {
        case HistoryStatusCode.START:
          return 'Start';
        case HistoryStatusCode.SUBMITTING:
          return 'Submitting request';
        case HistoryStatusCode.SUCCESS:
          return 'Success';
        default:
          return '';
      }
    } else {
      switch (errorCode) {
        case HistoryErrorCode.SERVER_INVALID:
          return 'Service unavailable';
        case HistoryErrorCode.SUBMIT_FAILED:
          return lastError && lastError > '' ? lastError : 'Unknown error';
        default:
          return '';
      }
    }
  }, [isProcessing, statusCode, errorCode, lastError]);

  useEffect(() => {
    if (!isProcessing) {
      if (statusCode === HistoryStatusCode.SUCCESS) {
        setSuccessMessage('Success');
      } else if (statusCode === HistoryStatusCode.FAILED) {
        setErrorMessage(statusMessage);
      }
    }
  }, [isProcessing, statusCode, statusMessage, errorCode]);

  const claimStatusMessage = useMemo(() => {
    if (isClaimProcessing || claimStatusCode !== ClaimStatusCode.FAILED) {
      switch (claimStatusCode) {
        case ClaimStatusCode.START:
          return 'Start';
        case ClaimStatusCode.CLAIMING:
          return 'Claiming tokens';
        case ClaimStatusCode.SUBMITTING:
          return 'Submitting result';
        case ClaimStatusCode.SUCCESS:
          return 'Success';
        default:
          return '';
      }
    } else {
      return claimLastError && claimLastError > ''
        ? claimLastError
        : 'Unknown error';
    }
  }, [isClaimProcessing, claimStatusCode, claimLastError]);

  useEffect(() => {
    if (!isClaimProcessing) {
      if (claimStatusCode === ClaimStatusCode.SUCCESS) {
        setClaimSuccessMessage('Success');
      } else if (claimStatusCode === ClaimStatusCode.FAILED) {
        setClaimErrorMessage(claimStatusMessage);
      }
    }
  }, [
    isClaimProcessing,
    claimStatusCode,
    claimSuccessMessage,
    claimStatusMessage,
  ]);

  return (
    <>
      <Container className="container">
        <Stepper orientation="vertical" className="step">
          <Step expanded>
            <StepButton>
              <span className="step-btn-title">Source</span>
            </StepButton>
            <StepContent>
              <StepDescription>
                <div className={classes.chainStepCaption}>
                  Please connect your wallet to check your deposit status.
                </div>
              </StepDescription>
              <div style={{ width: '32rem' }}>
                <TextField
                  label="Wallet Address"
                  variant="outlined"
                  fullWidth
                  value={walletAddress}
                  onChange={handleWalletAddressChange}
                  disabled
                />
              </div>
              <div className="step-btn-wallet-container">
                <SolanaWalletKey />
                <div className="step-btn-divider" />
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    !isAddressForSolana(solanaAddress as PublicKey | string) ||
                    isProcessing
                  }
                  onClick={onHistoryButtonClick}
                >
                  {isProcessing ? 'Processing' : 'Request'}
                </Button>
              </div>
              {!errorMessage && statusMessage ? (
                <Typography
                  variant="body2"
                  color="primary"
                  className={classes.statusMessage}
                >
                  {statusMessage}
                </Typography>
              ) : null}
              {errorMessage ? (
                <Typography
                  variant="body2"
                  color="error"
                  className={classes.statusMessage}
                >
                  {errorMessage}
                </Typography>
              ) : null}
              {!errorMessage && successMessage && walletAddress ? (
                <div>
                  <div style={{ paddingTop: '3rem' }}>
                    {historyResult.length > 0 ? (
                      <div>
                        <Typography variant="h6">Deposited Tokens</Typography>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Tx Hash</TableCell>
                                <TableCell>Period</TableCell>
                                <TableCell>Raffle Codes</TableCell>
                                <TableCell>Initial</TableCell>
                                <TableCell>Reward</TableCell>
                                <TableCell>&nbsp;</TableCell>
                                <TableCell>&nbsp;</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {historyResult.map((historyResultItem) => (
                                <TableRow key={historyResultItem.stakeTxHash}>
                                  <TableCell>
                                    {historyResultItem.stakeTxHash.substring(
                                      0,
                                      10,
                                    )}
                                    {historyResultItem.stakeTxHash.length >=
                                      10 && `...`}
                                    <ShowTxButton
                                      chainId={CHAIN_ID_SOLANA}
                                      txId={historyResultItem.stakeTxHash}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <Moment format="YYYY-MM-DD">
                                      {historyResultItem.stakeStartDate}
                                    </Moment>{' '}
                                    to{' '}
                                    <Moment format="YYYY-MM-DD">
                                      {historyResultItem.stakeEndDate}
                                    </Moment>
                                  </TableCell>
                                  <TableCell>
                                    {historyResultItem.status ===
                                      STATUS_STAKED ||
                                    historyResultItem.status === STATUS_CLAIMED
                                      ? historyResultItem.hodl_stake_raffle.map(
                                          (historyResultRaffleItem) => (
                                            <div
                                              key={
                                                historyResultRaffleItem.raffle_code
                                              }
                                            >
                                              {
                                                historyResultRaffleItem.raffle_code
                                              }
                                            </div>
                                          ),
                                        )
                                      : null}
                                  </TableCell>
                                  <TableCell>
                                    {historyResultItem.amountStaked}
                                  </TableCell>
                                  <TableCell>
                                    <span
                                      style={{
                                        color: 'rgba(25, 117, 230, 0.8)',
                                      }}
                                    >
                                      +
                                      {Math.round(
                                        historyResultItem.amountStaked *
                                          (0.411 / 100) *
                                          60,
                                      )}
                                    </span>
                                  </TableCell>
                                  <TableCell>
                                    {!historyResultItem.unstakeTxHash ? (
                                      <>
                                        <Button
                                          variant="outlined"
                                          onClick={() =>
                                            handleClaimButtonClick(
                                              historyResultItem.stakeTxHash,
                                              historyResultItem.hodl_merkle_infos
                                                ? historyResultItem
                                                    .hodl_merkle_infos[0]
                                                : null,
                                            )
                                          }
                                          disabled={
                                            new Date() <
                                              new Date(
                                                historyResultItem.stakeEndDate,
                                              ) ||
                                            historyResultItem.status !== 0 ||
                                            isClaimProcessing
                                          }
                                        >
                                          Claim
                                        </Button>
                                      </>
                                    ) : (
                                      <>
                                        {historyResultItem.unstakeTxHash.substring(
                                          0,
                                          10,
                                        )}
                                        {historyResultItem.unstakeTxHash
                                          .length >= 10 && `...`}
                                        <ShowTxButton
                                          chainId={CHAIN_ID_SOLANA}
                                          txId={historyResultItem.unstakeTxHash}
                                        />
                                      </>
                                    )}
                                    {stakeTxId ===
                                    historyResultItem.stakeTxHash ? (
                                      <>
                                        {!historyResultItem.unstakeTxHash &&
                                        !claimErrorMessage &&
                                        claimStatusMessage ? (
                                          <Typography
                                            variant="body2"
                                            color="primary"
                                            className={classes.statusMessage}
                                          >
                                            {claimStatusMessage}
                                          </Typography>
                                        ) : null}
                                        {!historyResultItem.unstakeTxHash &&
                                        claimResultTxId > '' ? (
                                          <div style={{ marginTop: '16px' }}>
                                            {claimResultTxId.substring(0, 10)}
                                            {claimResultTxId.length >= 10 &&
                                              `...`}
                                            <ShowTxButton
                                              chainId={CHAIN_ID_SOLANA}
                                              txId={claimResultTxId}
                                            />
                                          </div>
                                        ) : null}
                                        {claimErrorMessage ? (
                                          <Typography
                                            variant="body2"
                                            color="error"
                                            className={classes.statusMessage}
                                          >
                                            {claimErrorMessage}
                                          </Typography>
                                        ) : null}
                                      </>
                                    ) : null}
                                  </TableCell>
                                  <TableCell>
                                    <Button
                                      variant="outlined"
                                      onClick={() =>
                                        window.open(
                                          `https://staking.solchicks.io/`,
                                          `_blank`,
                                        )
                                      }
                                      disabled={
                                        new Date() <
                                          new Date(
                                            historyResultItem.stakeEndDate,
                                          ) ||
                                        historyResultItem.status === 0 ||
                                        isClaimProcessing
                                      }
                                    >
                                      Stake
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    ) : (
                      <Typography variant="h6">No entries found</Typography>
                    )}
                  </div>
                </div>
              ) : null}
            </StepContent>
          </Step>
        </Stepper>
      </Container>
    </>
  );
};
