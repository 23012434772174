/* eslint-disable @typescript-eslint/ban-ts-comment,no-restricted-syntax,@typescript-eslint/no-explicit-any */
import { useState, useMemo } from 'react';
import {
  Connection as RPCConnection,
  Connection,
  PublicKey,
  SystemProgram,
  Transaction,
  TransactionInstruction,
} from '@solana/web3.js';
import axios from 'axios';
import BN from 'bn.js';
import bs58 from 'bs58';
import { sha256 } from 'js-sha256';
import {
  AccountLayout,
  ASSOCIATED_TOKEN_PROGRAM_ID,
  Token,
  TOKEN_PROGRAM_ID,
} from '@solana/spl-token';
import { GUMDROP_DISTRIBUTOR_ID, SOLANA_HOST } from '../utils/consts';
import {
  getTransactionInfoOnSol,
  pubkeyToString,
  toPublicKey,
} from '../utils/solanaHelper';
import { useSolanaWallet } from '../contexts/SolanaWalletContext';
import { MerkleTree } from '../utils/merkleTree';
import { coder } from '../utils/merkleDistributor';
import {
  SOLCHICK_TOKEN_ADDRESS_ON_SOL,
  URL_SUBMIT_CLAIM,
} from '../utils/solchickConsts';
import { sleep } from '../utils/helper';
import ConsoleHelper from '../utils/consoleHelper';

export enum ClaimStatusCode {
  NONE = 0,
  START,
  CLAIMING,
  SUBMITTING,
  SUCCESS,
  FAILED,
}

export enum ClaimErrorCode {
  INVALID_CLAIMANT_HANDLE = 101,
  INVALID_CLAIMANT_HANDLE_MISMATCH = 102,
  INVALID_CLAIMANT_DISTRIBUTOR = 103,
  INVALID_CLAIMANT_DISTRIBUTOR_NONE = 111,
  INVALID_CLAIMANT_DISTRIBUTOR_OWNER = 112,
  INVALID_CLAIMANT_TOKEN_ACCOUNT = 121,
  INVALID_CLAIMANT_TOKEN = 122,
  INVALID_CLAIMANT_PROOF = 131,
  INVALID_CLAIMANT_PROOF_MISMATCH = 132,
}

interface IClaimStatus {
  claim(txId: string, params: IClaimParams): void;
  isProcessing: boolean;
  isReady: boolean;
  statusCode: ClaimStatusCode;
  lastError: string | null;
  sourceTxId: string;
  targetTxId: string;
}

export interface IClaimParams {
  distributor: string;
  amount: string;
  handle: string;
  proof: string;
  index: string;
  tokenAcc: string | PublicKey;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getClaimParams = (params: any): IClaimParams => ({
  distributor: params.distributor,
  amount: params.amount,
  handle: params.address,
  proof: params.proof,
  index: params.index,
  tokenAcc: params.token_account,
});

const createClaimStatus = (
  claim: (txId: string, params: IClaimParams) => void,
  isProcessing: boolean,
  isReady: boolean,
  statusCode = ClaimStatusCode.NONE,
  lastError: string | null,
  sourceTxId: string,
  targetTxId: string,
) => ({
  claim,
  isProcessing,
  isReady,
  statusCode,
  lastError,
  sourceTxId,
  targetTxId,
});

function useClaim(
  cbClaimed: (txId: string, result: string) => void,
): IClaimStatus {
  const [isProcessing, setIsProcessing] = useState(false);
  const [statusCode, setStatusCode] = useState(ClaimStatusCode.NONE);
  const [sourceTxId, setSourceTxId] = useState('');
  const [targetTxId, setTargetTxId] = useState('');
  const [lastError, setLastError] = useState('');
  const walletSolana = useSolanaWallet();
  const solanaConnection = useMemo(
    () => new Connection(SOLANA_HOST, 'confirmed'),
    [],
  );

  const setError = (errorMessage: string) => {
    setStatusCode(ClaimStatusCode.FAILED);
    setLastError(errorMessage);
    setIsProcessing(false);
  };

  const walletKeyOrPda = async (
    walletKey: PublicKey,
    handle: string,
    seed: PublicKey,
  ): Promise<[PublicKey, Array<Buffer>]> => {
    let key;
    try {
      key = new PublicKey(handle);
      ConsoleHelper(`Seed: ${seed}`);
    } catch (err) {
      ConsoleHelper(JSON.stringify(err));
      throw new Error(
        `Invalid URL (error: ${ClaimErrorCode.INVALID_CLAIMANT_HANDLE})`,
      );
    }

    if (!key.equals(walletKey)) {
      throw new Error(
        `Claimant wallet handle does not match connected wallet (error: 
        ${ClaimErrorCode.INVALID_CLAIMANT_HANDLE_MISMATCH})`,
      );
    }
    return [key, []];
  };

  const fetchDistributor = async (
    connection: RPCConnection,
    distributorStr: string,
  ) => {
    let key;
    try {
      key = new PublicKey(distributorStr);
    } catch (err) {
      throw new Error(
        `Invalid URL (error: ${ClaimErrorCode.INVALID_CLAIMANT_DISTRIBUTOR})`,
      );
    }
    const account = await connection.getAccountInfo(key);
    if (account === null) {
      throw new Error(
        `Invalid URL (error: ${ClaimErrorCode.INVALID_CLAIMANT_DISTRIBUTOR_NONE})`,
      );
    }
    if (!account.owner.equals(GUMDROP_DISTRIBUTOR_ID)) {
      throw new Error(
        `Invalid URL (error: ${ClaimErrorCode.INVALID_CLAIMANT_DISTRIBUTOR_OWNER})`,
      );
    }
    const info = coder.accounts.decode('MerkleDistributor', account.data);
    return [key, info];
  };

  const submitClaimResult = async (
    sTxId: string,
    address: string,
    txId: string,
    distributor: string,
    index: string | number,
  ) => {
    setTargetTxId(txId);
    const url = URL_SUBMIT_CLAIM(address, txId, distributor, index);
    axios.get(url).then(
      (results) => {
        ConsoleHelper(`processStakeResult: ${JSON.stringify(results)}`);
        if (results.data.success) {
          setStatusCode(ClaimStatusCode.SUCCESS);
          cbClaimed(sTxId, txId);
          setIsProcessing(false);
        } else {
          const errorMessage = results.data.error_message || 'Unknown error';
          setError(`${errorMessage} (Error code: ${results.data.error_code})`);
        }
      },
      (error) => {
        ConsoleHelper(`processStakeResult: ${error}`);
        setError(`Unknown error`);
      },
    );
  };

  const buildMintClaim = async (params: IClaimParams) => {
    const { publicKey: walletPublicKey } = walletSolana;
    if (!solanaConnection || !walletPublicKey) {
      throw new Error(`Wallet not connected`);
    }
    const distTokenAccount = await solanaConnection.getAccountInfo(
      toPublicKey(params.tokenAcc),
    );
    if (distTokenAccount === null) {
      throw new Error(
        `Invalid URL (error: ${ClaimErrorCode.INVALID_CLAIMANT_TOKEN_ACCOUNT})`,
      );
    }
    const tokenAccountInfo = AccountLayout.decode(distTokenAccount.data);
    const mint = new PublicKey(tokenAccountInfo.mint);
    ConsoleHelper(
      `CHICKS Token: ${pubkeyToString(mint)}`,
      toPublicKey(SOLCHICK_TOKEN_ADDRESS_ON_SOL).toString(),
    );

    if (!mint.equals(toPublicKey(SOLCHICK_TOKEN_ADDRESS_ON_SOL))) {
      throw new Error(
        `Invalid URL (error: ${ClaimErrorCode.INVALID_CLAIMANT_TOKEN})`,
      );
    }

    const [secret, pdaSeeds] = await walletKeyOrPda(
      walletPublicKey,
      params.handle,
      mint,
    );
    const leaf = Buffer.from([
      ...new BN(params.index).toArray('le', 8),
      // @ts-ignore
      ...secret.toBuffer(),
      // @ts-ignore
      ...mint.toBuffer(),
      ...new BN(params.amount).toArray('le', 8),
    ]);

    const proof =
      params.proof === ''
        ? []
        : params.proof.split(',').map((b) => {
            const ret = Buffer.from(bs58.decode(b));
            if (ret.length !== 32)
              throw new Error(
                `Invalid URL (error: ${ClaimErrorCode.INVALID_CLAIMANT_PROOF})`,
              );
            return ret;
          });

    const [distributorKey, distributorInfo] = await fetchDistributor(
      solanaConnection,
      pubkeyToString(params.distributor),
    );

    const matches = MerkleTree.verifyClaim(
      leaf,
      proof,
      Buffer.from(distributorInfo.root),
    );

    if (!matches) {
      throw new Error(
        `Invalid URL (error: ${ClaimErrorCode.INVALID_CLAIMANT_PROOF_MISMATCH}`,
      );
    }

    const [claimStatus, cbump] = await PublicKey.findProgramAddress(
      [
        Buffer.from('ClaimStatus'),
        Buffer.from(new BN(params.index).toArray('le', 8)),
        distributorKey.toBuffer(),
      ],
      GUMDROP_DISTRIBUTOR_ID,
    );

    // check association token account.
    const [walletTokenKey] = await PublicKey.findProgramAddress(
      [
        walletPublicKey.toBuffer(),
        TOKEN_PROGRAM_ID.toBuffer(),
        mint.toBuffer(),
      ],
      ASSOCIATED_TOKEN_PROGRAM_ID,
    );

    const setup: Array<TransactionInstruction> = [];
    if ((await solanaConnection.getAccountInfo(walletTokenKey)) === null) {
      setup.push(
        Token.createAssociatedTokenAccountInstruction(
          ASSOCIATED_TOKEN_PROGRAM_ID,
          TOKEN_PROGRAM_ID,
          mint,
          walletTokenKey,
          walletPublicKey,
          walletPublicKey,
        ),
      );
    }

    const temporalSigner =
      distributorInfo.temporal.equals(PublicKey.default) ||
      secret.equals(walletPublicKey)
        ? walletPublicKey
        : distributorInfo.temporal;

    const claimAirdrop = new TransactionInstruction({
      programId: GUMDROP_DISTRIBUTOR_ID,
      keys: [
        {
          pubkey: toPublicKey(params.distributor),
          isSigner: false,
          isWritable: true,
        },
        { pubkey: claimStatus, isSigner: false, isWritable: true },
        {
          pubkey: toPublicKey(params.tokenAcc),
          isSigner: false,
          isWritable: true,
        },
        { pubkey: walletTokenKey, isSigner: false, isWritable: true },
        { pubkey: temporalSigner, isSigner: true, isWritable: false },
        { pubkey: walletPublicKey, isSigner: true, isWritable: false }, // payer
        { pubkey: SystemProgram.programId, isSigner: false, isWritable: false },
        { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
      ],
      data: Buffer.from([
        // @ts-ignore
        ...Buffer.from(sha256.digest('global:claim')).slice(0, 8),
        ...new BN(cbump).toArray('le', 1),
        ...new BN(params.index).toArray('le', 8),
        ...new BN(params.amount).toArray('le', 8),
        // @ts-ignore
        ...secret.toBuffer(),
        ...new BN(proof.length).toArray('le', 4),
        // @ts-ignore
        ...Buffer.concat(proof),
      ]),
    });
    return [[...setup, claimAirdrop], pdaSeeds, []];
  };

  const processClaim = async (txId: string, params: IClaimParams) => {
    const { publicKey: walletPublicKey, sendTransaction } = walletSolana;
    if (!solanaConnection || !walletPublicKey) {
      throw new Error(`Wallet not connected`);
    }
    setIsProcessing(true);
    setStatusCode(ClaimStatusCode.START);

    const [instructions] = await buildMintClaim(params);
    const transaction = new Transaction({
      feePayer: walletPublicKey,
      recentBlockhash: (
        await solanaConnection.getRecentBlockhash('singleGossip')
      ).blockhash,
    });

    for (const instr of instructions) {
      // @ts-ignore
      transaction.add(instr);
    }

    setStatusCode(ClaimStatusCode.CLAIMING);
    try {
      // const signature = '1111111';
      const signature = await sendTransaction(transaction, solanaConnection);
      await solanaConnection.confirmTransaction(signature, 'processed');
      ConsoleHelper(`processClaim -> claimResult: ${signature}`);
      await sleep(5000);
      const txInfo = await getTransactionInfoOnSol(solanaConnection, signature);
      ConsoleHelper(
        `getTransactionInfoOnSol: txId: ${signature} - result ${JSON.stringify(
          txInfo,
        )}`,
      );
      if (!txInfo || !txInfo.meta || txInfo.meta.err) {
        setError('Failed');
        return;
      }
      setStatusCode(ClaimStatusCode.SUBMITTING);
      await submitClaimResult(
        txId,
        pubkeyToString(walletPublicKey),
        signature,
        params.distributor,
        params.index,
      );
    } catch (e) {
      ConsoleHelper(`processClaim -> error: ${JSON.stringify(e)}`);
      setError(`Claim failed with error: ${JSON.stringify(e)}`);
    }
  };

  const claim = async (txId: string, params: IClaimParams) => {
    try {
      ConsoleHelper(`claim`, txId, params);
      setSourceTxId(txId);
      await processClaim(txId, params);
    } catch (e) {
      ConsoleHelper('error', e);
      // @ts-ignore
      setError(e.message);
    }
  };

  const isReady = useMemo(() => {
    const { publicKey: walletPublicKey } = walletSolana;
    return !(!solanaConnection || !walletPublicKey);
  }, [walletSolana, solanaConnection]);
  return createClaimStatus(
    claim,
    isProcessing,
    isReady,
    statusCode,
    lastError,
    sourceTxId,
    targetTxId,
  );
}

export default useClaim;
