import './App.scss';
import React, { useCallback, useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import {
  Routes,
  Route,
  useNavigate,
  Link as RouterLink,
} from 'react-router-dom';
import {
  AppBar,
  Container,
  Link,
  makeStyles,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { BrowserView, MobileView } from 'react-device-detect';
import Countdown from 'react-countdown';
import NumberFormat from 'react-number-format';
import SolChicksLogo from './icons/chicks.svg';
import { COLORS, theme } from './muiTheme.js';
import { StatusView } from './pages/StatusView';
import useStakeStatus from './hooks/useDepositStatus';

const useStyles = makeStyles(() => ({
  appBar: {
    background: COLORS.nearBlackWithMinorTransparency,
    '& > .MuiToolbar-root': {
      margin: 'auto',
      width: '100%',
      maxWidth: 1230,
    },
  },
  spacer: {
    height: '4em',
  },
  horizontalSpacer: {
    height: '4em',
    width: '1em',
  },
  brandLink: {
    display: 'inline-flex',
    alignItems: 'center',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  bg: {
    backgroundColor: '#000008',
    color: '#ffffff',
    background:
      'linear-gradient(160deg, rgba(69,74,117,.1) 0%, ' +
      'rgba(138,146,178,.1) 33%, rgba(69,74,117,.1) 66%, ' +
      'rgba(98,104,143,.1) 100%), linear-gradient(45deg, ' +
      'rgba(153,69,255,.1) 0%, ' +
      'rgba(121,98,231,.1) 20%, ' +
      'rgba(0,209,140,.1) 100%)',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    overflowX: 'hidden',
  },
  solChicksIcon: {
    height: 32,
    filter: 'contrast(0)',
    transition: 'filter 0.5s',
    '&:hover': {
      filter: 'contrast(1)',
    },
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
    display: 'inline-block',
  },
  content: {
    margin: theme.spacing(2, 0),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(4, 0),
    },
  },
  tab: {
    fontFamily: 'Bergern, Poppins, sans-serif',
    fontSize: '1.25rem',
    lineHeight: '1.75',
    textTransform: 'none',
  },
  brandText: {
    fontSize: 16,
    background: `linear-gradient(160deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.5) 100%);`,
    WebkitBackgroundClip: 'text',
    backgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    MozBackgroundClip: 'text',
    MozTextFillColor: 'transparent',
    letterSpacing: '1px',
    display: 'inline-block',
    marginLeft: theme.spacing(0.5),
  },
}));
function App() {
  const navigate = useNavigate();
  const [stakeAmount, setStakeAmount] = useState('');
  const classes = useStyles();
  const { pathname } = useLocation();
  const handleTabChange = useCallback(
    (event, value) => {
      navigate(value);
    },
    [navigate],
  );
  const { getSummary, amount } = useStakeStatus();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const renderer = ({ formatted: { hours, minutes, seconds }, completed }) => {
    if (completed) {
      return <span>Rewards Window Closed</span>;
    }
    return (
      <span>
        Rewards Window Closing In: {hours}h {minutes}m {seconds}s
      </span>
    );
  };

  useEffect(() => {
    getSummary();
  }, [getSummary]);
  useEffect(() => {
    setStakeAmount(amount);
  }, [amount]);

  return (
    <div className={classes.bg}>
      <BrowserView>
        <AppBar position="static" color="inherit" className={classes.appBar}>
          <Toolbar>
            <Link component={RouterLink} to="/" className={classes.brandLink}>
              <img
                src={SolChicksLogo}
                alt="SolChicks"
                className={classes.solChicksIcon}
              />
            </Link>
            <div className={classes.horizontalSpacer} />
            {stakeAmount ? (<Typography className={classes.brandText} >
              Amount Deposited:{' '}
              <NumberFormat
                value={stakeAmount}
                displayType="text"
                thousandSeparator
              />{' '}
              $CHICKS
            </Typography>) : null}
            <div style={{ marginLeft: 'auto' }}>
              <Typography className={classes.brandText}>
                <Countdown
                  date="2022-01-16T02:00:00+00:00"
                  daysInHours
                  renderer={renderer}
                />
              </Typography>
            </div>
          </Toolbar>
        </AppBar>
      </BrowserView>
      <MobileView>
        <AppBar position="static" color="inherit" className={classes.appBar}>
          <Toolbar>
            <Link component={RouterLink} to="/" className={classes.brandLink}>
              <img
                src={SolChicksLogo}
                alt="SolChicks"
                className={classes.solChicksIcon}
              />
            </Link>
          </Toolbar>
        </AppBar>
      </MobileView>
      <BrowserView>
        <div className={classes.content}>
          <Container>
            <Tabs
              value={pathname}
              variant="fullWidth"
              onChange={handleTabChange}
              indicatorColor="primary"
            >
              <Tab className={classes.tab} label="Status" value="/" />
            </Tabs>
          </Container>
        </div>
        <Routes>
          <Route path="/" element={<StatusView />} />
          <Route path="*" element={<StatusView />} />
        </Routes>
        <div className={classes.spacer} />
      </BrowserView>
      <MobileView>
        <div className={classes.content}>
          <Container className="container">
            Please open this website in your desktop browser, mobile is
            currently unsupported.
            {stakeAmount ? (<div style={{ paddingTop: '1em' }}>
              Amount Deposited:{' '}
              <NumberFormat
                value={stakeAmount}
                displayType="text"
                thousandSeparator
              />{' '}
              $CHICKS
            </div>) : null}
          </Container>
        </div>
      </MobileView>
    </div>
  );
}

export default App;
