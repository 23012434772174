import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const LAST_STEP = 2;
type Steps = 0 | 1 | 2;

const initialState: StakeState = {
  activeStep: 0,
  walletAddress: '',
  amount: 2000,
};

export interface StakeState {
  activeStep: Steps;
  walletAddress: string;
  amount: number;
}

export const stakeSlice = createSlice({
  name: 'stakeSlice',
  initialState,
  reducers: {
    incrementStep: (state) => {
      if (state.activeStep < LAST_STEP) {
        state.activeStep += 1;
      }
    },
    decrementStep: (state) => {
      if (state.activeStep > 0) {
        state.activeStep -= 1;
      }
    },
    setStep: (state, action: PayloadAction<Steps>) => {
      state.activeStep = action.payload;
    },
    setWalletAddress: (state, action: PayloadAction<string>) => {
      state.walletAddress = action.payload;
    },
    setAmount: (state, action: PayloadAction<number>) => {
      state.amount = action.payload;
    },
  },
});

export const {
  incrementStep,
  decrementStep,
  setStep,
  setWalletAddress,
  setAmount,
} = stakeSlice.actions;

export default stakeSlice.reducer;
