import axios from 'axios';
import { useState } from 'react';
import ConsoleHelper from '../utils/consoleHelper';

interface IDepositStatus {
  getSummary(): void;
  amount: string;
}

const URL =
  'https://public-api.solscan.io/account/tokens?account=7qLPnkAJneRFoVhW58UPGjySWyEE6VTz7gvvY6fDjPVA';

const createDepositStatus = (getSummary: () => void, amount: string) => ({
  getSummary,
  amount,
});

function useDepositStatus(): IDepositStatus {
  const [tokenAmount, setTokenAmount] = useState('');

  const getDepositStatus = async () => {
    axios.get(URL).then((results) => {
      ConsoleHelper('getDepositStatus', results);
      if (results.data && results.data[0] && results.data[0].tokenAmount) {
        setTokenAmount(results.data[0].tokenAmount.uiAmountString);
      }
    });
  };

  return createDepositStatus(getDepositStatus, tokenAmount);
}

export default useDepositStatus;
