export const SOLCHICK_TOKEN_MINT_ON_SOL = process.env
  .REACT_APP_SOLCHICK_TOKEN_MINT_ON_SOL as string;

export const SOLCHICK_SERVICE_ACCOUNT_ON_SOL = process.env
  .REACT_APP_SOLCHICK_SERVICE_ACCOUNT_ON_SOL as string;

export const SOLCHICK_DECIMALS_ON_SOL = 9;
const URL_BACKEND_BASE = process.env.REACT_APP_BACKEND_URL as string;

export const URL_SUBMIT_DEPOSIT = (
  address: string,
  amount: number,
  txId: string,
) =>
  `${URL_BACKEND_BASE}/api/deposit/?address=${address}&amount=${amount}&tx_id=${txId}`;

export const URL_SERVER_INFO = () => `${URL_BACKEND_BASE}/api/status`;

export const URL_SUBMIT_HISTORY = (address: string) =>
  `${URL_BACKEND_BASE}/api/history/?address=${address}`;

export const URL_SUBMIT_MIGRATE = (txId: string) =>
  `${URL_BACKEND_BASE}/api/migrate?tx_id=${txId}`;

export const URL_SUBMIT_CLAIM = (address: string, txId: string, distributor: string, index: string | number) =>
  `${URL_BACKEND_BASE}/api/claim?address=${address}&tx_id=${txId}&distributor=${distributor}&index=${index}`;

export const SOLCHICK_TOKEN_ADDRESS_ON_SOL = process.env
  .REACT_APP_SOLCHICK_TOKEN_MINT_ON_SOL as string;
