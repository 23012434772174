/* eslint-disable */
import axios from 'axios';
import { useMemo, useState } from 'react';
import { ConfirmOptions, Connection } from '@solana/web3.js';
import { Provider as AnchorProvider } from '@project-serum/anchor';
import { AnchorWallet } from '@solana/wallet-adapter-react';
import { URL_SERVER_INFO, URL_SUBMIT_HISTORY } from '../utils/solchickConsts';
import ConsoleHelper from '../utils/consoleHelper';
import { useSolanaWallet } from '../contexts/SolanaWalletContext';
import { SOLANA_HOST } from '../utils/consts';
import { pubkeyToString } from '../utils/solanaHelper';
import {STATUS_CLAIMED, STATUS_MIGRATED} from "../utils/hodlHelper";

export enum HistoryStatusCode {
  NONE = 0,
  START,
  SUCCESS,
  SUBMITTING,
  FAILED = 101,
}

export enum HistoryErrorCode {
  NO_ERROR,
  SERVER_INVALID,
  SUBMIT_FAILED,
}

interface IHistoryStatus {
  history(): void;
  updateHistoryForMigrate(txId: string, result: string): void;
  updateHistoryForClaim(txId: string, result: string): void;
  isProcessing: boolean;
  historyResult: IStake[];
  statusCode: HistoryStatusCode;
  errorCode: HistoryErrorCode;
  lastError: string | null;
}

export interface IStake {
  address: string;
  stakeTxHash: string;
  unstakeTxHash: string;
  hodl_merkle_infos: any;
  amountStaked: number;
  stakeClaimDate: Date;
  stakeStartDate: Date;
  stakeEndDate: Date;
  hodl_stake_raffle: IStakeRaffle[];
  status: number;
  migrate_tx_hash: string;
}

export interface IStakeRaffle {
  raffle_code: string;
}

const createHistoryStatus = (
  history: () => void,
  updateHistoryForMigrate: (txId: string, result: string) => void,
  updateHistoryForClaim: (txId: string, result: string) => void,
  isProcessing: boolean,
  historyResult: IStake[],
  statusCode = HistoryStatusCode.NONE,
  errorCode: HistoryErrorCode,
  lastError: string | null,
) => ({
  history,
  updateHistoryForMigrate,
  updateHistoryForClaim,
  isProcessing,
  historyResult,
  statusCode,
  errorCode,
  lastError,
});

function useHistory(): IHistoryStatus {
  const [isProcessing, setIsProcessing] = useState(false);
  const [statusCode, setStatusCode] = useState(HistoryStatusCode.NONE);
  const [errorCode, setErrorCode] = useState(HistoryErrorCode.NO_ERROR);
  const [lastError, setLastError] = useState('');
  const [historyResult, setHistoryResult] = useState([]);
  const walletSolana = useSolanaWallet();

  const solanaConnection = useMemo(
    () => new Connection(SOLANA_HOST, 'confirmed'),
    [],
  );

  async function getAnchorProvider() {
    const opts = {
      preflightCommitment: 'confirmed',
    };
    if (!solanaConnection || !walletSolana) {
      return null;
    }
    return new AnchorProvider(
      solanaConnection,
      walletSolana as unknown as AnchorWallet,
      opts.preflightCommitment as unknown as ConfirmOptions,
    );
  }

  const getServerInfo = async () => {
    try {
      const result = await axios.get(URL_SERVER_INFO());
      if (result && result.data && result.data.success) {
        return true;
      }
    } catch (e) {
      ConsoleHelper(`getServerInfo: ${e}`);
    }
    return false;
  };

  const setError = (error: HistoryErrorCode) => {
    ConsoleHelper('useHistory setError', error);
    setStatusCode(HistoryStatusCode.FAILED);
    setErrorCode(error);
    setIsProcessing(false);
  };

  const submitHistoryRequest = async (address: string) => {
    const url = URL_SUBMIT_HISTORY(address);
    ConsoleHelper(`submitHistoryRequest: ${url}`);
    axios.get(url).then(
      (results) => {
        if (results.data.success) {
          setHistoryResult(results.data.data);
          ConsoleHelper(`setHistoryResult: ${JSON.stringify(historyResult)}`);
          setIsProcessing(false);
          setStatusCode(HistoryStatusCode.SUCCESS);
        } else {
          const errorMessage = results.data.error_message || 'Unknown error';
          setLastError(
            `${errorMessage} (Error code: ${results.data.error_code})`,
          );
          setError(HistoryErrorCode.SUBMIT_FAILED);
        }
      },
      (error) => {
        ConsoleHelper(`submitHistoryRequest: ${error}`);
        setLastError(`Unknown error`);
        setError(HistoryErrorCode.SUBMIT_FAILED);
      },
    );
  };

  const getHistory = async () => {
    const { publicKey: walletPublicKey } = walletSolana;
    if (!walletPublicKey || !solanaConnection) {
      return false;
    }

    const provider = await getAnchorProvider();
    if (!provider) {
      return false;
    }

    setIsProcessing(true);
    setStatusCode(HistoryStatusCode.START);

    const ret = await getServerInfo();
    if (!ret) {
      setError(HistoryErrorCode.SERVER_INVALID);
      return false;
    }

    setStatusCode(HistoryStatusCode.SUBMITTING);

    await submitHistoryRequest(pubkeyToString(walletPublicKey));

    return true;
  };

  const history = async () => {
    ConsoleHelper('history -- start');

    await getHistory();
  };

  const updateHistoryForMigrate = (txId: string, resultTxId: string) => {
    ConsoleHelper(`updateHistoryForMigrate: ${txId}, resultTxId: ${resultTxId}`);
    const newHistoryResult = historyResult.map((row: IStake) => {
      if (txId === row.stakeTxHash) {
        return {...row, migrate_tx_hash: resultTxId, status: STATUS_MIGRATED}
      } else {
        return {...row}
      }
    })
    // @ts-ignore
    setHistoryResult(newHistoryResult);
  }

  const updateHistoryForClaim = (txId: string, resultTxId: string) => {
    ConsoleHelper(`updateHistoryForClaim: ${txId}, resultTxId: ${resultTxId}`);
    const newHistoryResult = historyResult.map((row: IStake) => {
      if (txId === row.stakeTxHash) {
        return {...row, unstakeTxHash: resultTxId, status: STATUS_CLAIMED}
      } else {
        return {...row}
      }
    })
    // @ts-ignore
    setHistoryResult(newHistoryResult);
  }

  return createHistoryStatus(
    history,
    updateHistoryForMigrate,
    updateHistoryForClaim,
    isProcessing,
    historyResult,
    statusCode,
    errorCode,
    lastError,
  );
}

export default useHistory;
