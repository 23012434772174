export type ChainId = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 10001;
export const CHAIN_ID_NONE: ChainId = 0;
export const CHAIN_ID_SOLANA: ChainId = 1;
export const CHAIN_ID_ETH: ChainId = 2;
export const CHAIN_ID_TERRA: ChainId = 3;
export const CHAIN_ID_BSC: ChainId = 4;
export const CHAIN_ID_POLYGON: ChainId = 5;
export const CHAIN_ID_AVAX: ChainId = 6;
export const CHAIN_ID_ETHEREUM_ROPSTEN: ChainId = 10001;
export const WSOL_ADDRESS = 'So11111111111111111111111111111111111111112';
export const WSOL_DECIMALS = 9;
export const MAX_VAA_DECIMALS = 8;
